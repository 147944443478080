/*
 * @Author: ajx
 * @Email: jianxin.an@medbanks.cn
 * @Date: 2020-11-16 17:21:22
 * @Last Modified by: ajx
 * @Last Modified time: 2021-03-29 14:45:54
 * @Description: Description
 * @Route: Route
 */

<template>
    <div>
        <div
            :class="{fixed: fixed}"
            class="btn_container pl18 pr18">
            <div
                v-if="btns.length"
                class="wrapper">
                <Button
                    v-for="item in btns"
                    :key="item.id"
                    :class="{'btn_plain': item.type === 'plain', 'btn_disabled': disabled}"
                    :disabled="disabled"
                    @click="handleClick({ id: item.id })"
                    type="default"
                    class="btn btn_active">
                    {{ item.title }}
                </Button>
            </div>
            <div
                v-else
                class="wrapper">
                <Button
                    :disabled="disabled"
                    :class="{'btn_disabled': disabled}"
                    @click="handleClick"
                    type="default"
                    class="btn btn_active">
                    {{ title }}
                </Button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        fixed: {
            type: Boolean,
            default: true,
        },
        btns: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleClick({ id }) {
            if (this.disabled) return;
            if (id) {
                this.$emit('clickEvent', { id });
                return;
            }
            this.$emit('clickEvent');
        },
    },
};
</script>
<style lang="scss" scoped>
.fixed {
    // position: absolute; // 特殊需要，改为绝对定位
    position: fixed;
    bottom: constant(safe-area-inset-bottom);
    bottom: env(safe-area-inset-bottom);
    bottom: 0;
    left: 0;
    right: 0;
}
.btn_container {
    background: #fff;
    // width: 100%;
    // box-sizing: border-box;
    box-shadow: 0 0 8px 0 rgba(60, 107, 240, 0.1);

    .wrapper {
        display: flex;
        align-items: center;
        height: 60px;
    }

    .btn {
        flex: 1;
        height: 44px;
        line-height: 44px;
        border-radius: 40px;
        border: none;
        font-family: $font-family-PF-M;
        font-size: 16px;
        font-weight: 500;

        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    .btn_active {
        color: #fff;
        background: #f36218;
    }

    .btn_plain {
        color: #f36218;
        background: #fff;
        border: 1px solid #f36218;
    }

    .btn_disabled {
        background: #f36218;
        border-radius: 40px;
        opacity: 0.5;
    }
}
</style>
