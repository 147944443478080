import { getDevice } from '@/utils';

const logicLayerNotReturnMixin = {
    mounted() {
        /**
         * 以下代码主要作用：
         * 1.获取设备信息，通过设备信息判断是安卓手机还是ios
         * 2.ios中输入法弹窗会将页面顶起，导致视图虽然显示正常，但实际渲染图层并未归位（应该是输入法拼接到页面中加大了整体页面高度，归位时图层不能归位所致），
         * 所以需要手动让滚动条回到顶点
         */
        let isReset = true;
        const device = getDevice();

        const handleFocusIn = () => {
            isReset = false;
        };

        const handleFocusOut = () => {
            isReset = true;
            setTimeout(() => {
                // 当焦点在弹出层的输入框之间切换时先不归位
                if (isReset) {
                    window.scroll(0, 0); // 失焦后强制让页面归位
                }
            }, 300);
        };

        if (device === 'ios') {
            document.body.addEventListener('focusin', handleFocusIn);
            document.body.addEventListener('focusout', handleFocusOut);
        } else if (device === 'android') {
            window.onresize = () => {
                // 键盘弹起与隐藏都会引起窗口的高度发生变化
                const resizeHeight = document.documentElement.clientHeight
                    || document.body.clientHeight;
                if (resizeHeight < 10) {
                    // 当软键盘弹起，在此处操作
                    isReset = false;
                } else {
                    // 当软键盘收起，在此处操作
                    isReset = true;
                    setTimeout(() => {
                        if (isReset) {
                            window.scroll(0, 0); // 失焦后强制让页面归位
                        }
                    }, 300);
                }
            };
        }

        this.$once('hook:beforeDestroy', () => {
            document.body.removeEventListener('focusin', handleFocusIn);
            document.body.removeEventListener('focusout', handleFocusOut);
        });
    },
};

export default logicLayerNotReturnMixin;
