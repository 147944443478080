<template>
    <div class="container">
        <div
            @click="toDrugUsers"
            class="user mt8 mb8">
            <div class="title">
                用药人
            </div>
            <div class="icon">
                <span
                    v-if="drugUersSelected.personName"
                    class="name mr12">{{ drugUersSelected.personName }}</span>
                <span
                    v-if="drugUersSelected.gender"
                    class="mr12">{{ drugUersSelected.gender === 1 ? '男' : '女' }}</span>
                <span
                    v-if="drugUersSelected.age"
                    class="mr8">{{ drugUersSelected.age }}岁</span>
                <img
                    :src="require('@img/arrow_right_grey.png')"
                    class="icon_user"
                    alt="">
            </div>
        </div>
        <!-- <div class="tab">
            <Tabs
                v-model="currentTab"
                :title-active-color="'#3C6BF0'"
                :color="'#3C6BF0'"
                @change="handleTabChange">
                <Tab title="获取电子处方" />
                <Tab title="上传已有处方" />
            </Tabs>
        </div> -->
        <div
            v-show="currentTab === 0"
            class="disease mb8 pa18">
            <div class="header">
                线下已确诊疾病
            </div>
            <div class="body">
                <div
                    v-for="(item, index) in diseases"
                    :key="index"
                    class="tag mb8">
                    <div class="content">
                        {{ item.diseaseName }}
                    </div>
                    <div class="badge">
                        <img
                            :src="require('@img/icon_delete.png')"
                            @click="handleDeleteDisease(index)"
                            class="icon_delete"
                            alt="">
                    </div>
                </div>
                <div
                    @click="handleAddDisease"
                    class="tag add">
                    添加疾病
                </div>
            </div>
        </div>
        <div
            v-show="currentTab === 0"
            class="other_disease mb8">
            <div class="item pl18 pr18">
                <div class="title">
                    是否曾服用过该药且无禁忌症？
                </div>
                <div class="options">
                    <div
                        :class="{active: diseaseInvestigation.taboo === true}"
                        @click="handleSwitch('taboo', true)"
                        class="btn mr8">
                        是
                    </div>
                    <div
                        :class="{active: diseaseInvestigation.taboo === false}"
                        @click="handleSwitch('taboo', false)"
                        class="btn">
                        否
                    </div>
                </div>
            </div>
            <div class="van-hairline--bottom" />
            <div class="item  pl18 pr18">
                <div class="title">
                    有无过敏反应？
                </div>
                <div class="options">
                    <div
                        :class="{active: diseaseInvestigation.allergy === true}"
                        @click="handleSwitch('allergy', true)"
                        class="btn mr8">
                        是
                    </div>
                    <div
                        :class="{active: diseaseInvestigation.allergy === false}"
                        @click="handleSwitch('allergy', false)"
                        class="btn">
                        否
                    </div>
                </div>
            </div>
            <div class="van-hairline--bottom" />
            <div class="item  pl18 pr18">
                <div class="title">
                    有无不良反应？
                </div>
                <div class="options">
                    <div
                        :class="{active: diseaseInvestigation.adverseReaction === true}"
                        @click="handleSwitch('adverseReaction', true)"
                        class="btn mr8">
                        是
                    </div>
                    <div
                        :class="{active: diseaseInvestigation.adverseReaction === false}"
                        @click="handleSwitch('adverseReaction', false)"
                        class="btn">
                        否
                    </div>
                </div>
            </div>
        </div>
        <div class="supplement pt18 pb18">
            <div class="header mb6 pl18 pr18">
                <div class="title">
                    补充已就诊信息
                </div>
                <div class="count">
                    {{ fileList.length }}/{{ maxUploadCount }}
                </div>
            </div>
            <div class="body pl18 pr18">
                <div
                    v-show="currentTab === 0"
                    class="desc">
                    <p>如病历/出院记录等</p>
                </div>
                <div
                    v-show="currentTab === 1"
                    class="desc">
                    <p>1.处方单不等于病历本、检查结果、伤患照片等</p>
                    <p>2.处方单需在有效期内使用</p>
                    <p>3.处方单信息需与有预约单的用药人、药品名称、规格、剂型、数量等信息一致</p>
                </div>
                <div class="images">
                    <Uploader
                        v-model="fileList"
                        :after-read="handleUploadImg"
                        :max-size="5 * 1024 * 1024"
                        :max-count="maxUploadCount"
                        @oversize="handleOversize">
                        <template #default>
                            <div class="uploader">
                                <div class="icon_uploader" />
                            </div>
                        </template>
                    </Uploader>
                </div>
            </div>
        </div>
        <Btn-Bottom
            :title="currentTab === 0 ? '免费问诊并开方' : '完成'"
            @clickEvent="handleSubmit" />

        <Action-Sheet
            v-model="isShow"
            :round="false"
            title="添加确诊疾病">
            <div class="content">
                <div class="body pl18 pr18">
                    <textarea
                        v-model.trim="content"
                        class="textarea"
                        placeholder="请输入已确诊疾病"
                        maxlength="40" />
                </div>
                <Btn-Bottom
                    :fixed="false"
                    :disabled="!content.length"
                    @clickEvent="handleSave"
                    title="保存" />
            </div>
        </Action-Sheet>

        <Dialog
            v-model="showDialog"
            :confirm-button-color="'#F76D32'"
            @confirm="handleConfirm"
            confirm-button-text="知道了"
            width="280px">
            <div class="tip">
                {{ tip }}
            </div>
        </Dialog>
    </div>
</template>
<script>
import {
    ActionSheet, Uploader, Dialog, Toast,
} from 'vant';
import BtnBottom from '@/components/btn-bottom';
import layerMixin from '@/mixins/layer';
import { mapState, mapMutations } from 'vuex';
import api from '@/api';

export default {
    name: 'Prescription',
    components: {
        // Tabs,
        // Tab,
        ActionSheet,
        Uploader,
        Dialog: Dialog.Component,
        BtnBottom,
    },
    mixins: [layerMixin],
    data() {
        return {
            currentTab: 1,
            isShow: false,
            content: '',
            maxUploadCount: 5,
            showDialog: false,
            tip: '',
            diseases: [],
            diseaseInvestigation: {
                taboo: '', // 禁忌
                allergy: '', // 过敏反应
                adverseReaction: '', // 不良反应
            },
            fileList: [],
            eletronicPrescriptions: [],
            availablePrescriptions: [],
        };
    },
    computed: {
        ...mapState({
            notClearMark: (store) => store.prescription.notClearMark,
        }),
        drugUersSelected() {
            return this.$route.params.from === 'pay'
                ? this.$store.state.prescription.drugUersSelected
                : this.$store.state.drugUsers.selected;
        },
    },
    created() {
        const {
            currentTab, diseases, diseaseInvestigation, eletronicPrescriptions, availablePrescriptions,
        } = this.$store.state.prescription;
        this.currentTab = currentTab;
        this.diseases = diseases.slice();
        this.diseaseInvestigation = { ...diseaseInvestigation };
        this.fileList = currentTab === 0 ? eletronicPrescriptions.slice() : availablePrescriptions.slice();
    },
    beforeDestroy() {
        // this.updateUserSelected({ ...this.$store.state.prescription.drugUersSelected });
        if (this.notClearMark) return;
        this.clearPrescriptionInfo();
    },
    methods: {
        ...mapMutations('drugUsers', [
            'updateUserSelected',
        ]),
        ...mapMutations('prescription', [
            'updatePrescriptionInfo',
            'clearPrescriptionInfo',
            'saveCurrentTabState',
        ]),
        handleDeleteDisease(index) {
            this.diseases.splice(index, 1);
        },
        handleAddDisease() {
            this.content = '';
            this.isShow = true;
        },
        handleSave() {
            if (!this.content) return;
            if (this.diseases.some((item) => item.diseaseName === this.content)) {
                this.content = '';
                Toast('该标签已存在');
                return;
            }
            this.diseases.push({
                diseaseName: this.content,
            });
            this.isShow = false;
        },
        handleSwitch(key, value) {
            this.diseaseInvestigation[key] = this.diseaseInvestigation[key] === value ? '' : value;
        },
        handleTabChange(tab) {
            this.currentTab = tab;
            // 是否需要操作...
            if (tab === 0) {
                this.availablePrescriptions = this.fileList.slice();
                this.fileList = this.eletronicPrescriptions.slice();
            } else {
                this.eletronicPrescriptions = this.fileList.slice();
                this.fileList = this.availablePrescriptions.slice();
            }
        },
        handleConfirm() {
            this.showDialog = false;
        },
        handleUploadImg(file) {
            // 组件上传图片后会自动填充一条默认格式的数据，不同于我们期望的数据格式时，删除这条默认插入就好
            this.fileList.splice(this.fileList.length - 1, 1);
            api.uploadImg(file).then((res) => {
                if (res.code === '0') {
                    this.fileList.push({
                        url: res.data[0],
                    });
                }
            });
        },
        handleOversize() {
            Toast('图片大小不能超过5M');
        },
        toDrugUsers() {
            // 需要缓存存储
            this.storageTool({ to: 'drugUsers' });
            this.$router.push({
                name: 'drug-users-list',
            });
        },
        handleSubmit() {
            if (!this.drugUersSelected.personName) {
                this.tip = '请选择用药人';
                this.showDialog = true;
                return;
            }

            if (this.currentTab === 0) {
                if (!this.diseases.length) {
                    this.tip = '请填写线下已确诊疾病';
                    this.showDialog = true;
                    return;
                }
                if (this.diseaseInvestigation.taboo === '') {
                    this.tip = '是否曾服用过该药且无禁忌症不能为空';
                    this.showDialog = true;
                    return;
                }
                if (this.diseaseInvestigation.allergy === '') {
                    this.tip = '有无过敏反应不能为空';
                    this.showDialog = true;
                    return;
                }
                if (this.diseaseInvestigation.adverseReaction === '') {
                    this.tip = '有无不良反应不能为空';
                    this.showDialog = true;
                    return;
                }
                if (this.diseaseInvestigation.taboo === false) {
                    this.tip = '为了您的用药安全 建议您到线下医院问诊';
                    this.showDialog = true;
                    return;
                }
                if (this.diseaseInvestigation.allergy === true) {
                    this.tip = '您对该药品过敏，为了您的用药安全，建议前往线下问诊';
                    this.showDialog = true;
                    return;
                }
                if (this.diseaseInvestigation.adverseReaction === true) {
                    this.tip = '为了您的用药安全 建议您到线下医院问诊';
                    this.showDialog = true;
                    return;
                }
            }
            if (this.currentTab === 1) {
                if (!this.fileList.length) {
                    this.tip = '请上传处方';
                    this.showDialog = true;
                    return;
                }
            }
            // 需要缓存存储
            this.storageTool({ to: 'pay' });
            this.$router.push({
                name: 'pay',
            });
        },
        storageTool({ to }) {
            if (this.currentTab === 0) {
                this.eletronicPrescriptions = this.fileList.slice();
            } else {
                this.availablePrescriptions = this.fileList.slice();
            }

            const {
                currentTab, diseases, diseaseInvestigation, eletronicPrescriptions, availablePrescriptions, drugUersSelected,
            } = this;

            if (this.currentTab === 0) {
                const param = {
                    notClearMark: true,
                    currentTab,
                    diseases,
                    diseaseInvestigation,
                    eletronicPrescriptions,
                };
                if (to === 'pay') {
                    param.drugUersSelected = drugUersSelected;
                }
                this.updatePrescriptionInfo(param);
            } else {
                const param = {
                    notClearMark: true,
                    currentTab,
                    availablePrescriptions,
                };
                if (to === 'pay') {
                    param.drugUersSelected = drugUersSelected;
                }
                this.updatePrescriptionInfo(param);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.container {
    overflow: hidden;
    padding-bottom: 70px;
}
.user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 0 18px;
    height: 54px;
    background: #fff;

    .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        // font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333;

        &::before {
            content: '';
            margin-right: 6px;
            width: 16px;
            height: 16px;
            background: url('~@img/icon_user.png') no-repeat;
            background-size: cover;
        }
    }

    .icon {
        display: flex;
        align-items: center;
        font-size: 14px;
        // font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;

        .name {
            font-size: 16px;
            // font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
        }
    }
}
.tab {
    margin-bottom: 1px;
    background: #fff;
    box-shadow: 0 0 8px 0 rgba(60, 107, 240, 0.1);
}
.disease {
    background: #fff;

    .header {
        margin: 4px 0 16px;
        font-size: 16px;
        // font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333;

        &::before {
            content: '*';
            margin-right: 4px;
            font-size: 14px;
            font-weight: 400;
            color: #fa3e3e;
        }
    }

    .body {
        display: flex;
        flex-wrap: wrap;

        .tag {
            position: relative;
            margin-right: 14px;
            padding: 0 10px;
            max-width: 100%;
            height: 28px;
            line-height: 26px;
            background: rgba(247, 109, 50, 0.05);
            border-radius: 100px;
            border: 1px solid #f76d32;
            // font-family: PingFangSC-Regular, PingFang SC;
            font-size: 14px;
            font-weight: 400;
            color: #f76d32;
            box-sizing: border-box;

            .content {
                max-width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow-x: hidden;
            }

            .badge {
                position: absolute;
                top: -8px;
                right: -6px;
            }
        }
        .add::before {
            content: '';
            display: inline-block;
            width: 9px;
            height: 9px;
            background: url('~@img/icon_plus.png') no-repeat;
            background-size: cover;
        }
    }
}
.other_disease {
    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        background: #fff;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);

        .title {
            font-size: 14px;
            // font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333;
        }

        .options {
            display: flex;

            .btn {
                width: 52px;
                height: 28px;
                line-height: 28px;
                border-radius: 15px;
                text-align: center;
                border: 1px solid #f36218;
                color: #f36218;
                font-size: 14px;

                &.active {
                    color: #fff;
                    background: #f36218;
                }
            }
        }
    }
}
.supplement {
    background: #fff;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 16px;
            // font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333;
        }

        .count {
            font-size: 12px;
            // font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #90949e;
        }
    }

    .body {
        .desc {
            margin-bottom: 21px;
            font-size: 14px;
            // font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #62636c;

            p {
                line-height: 20px;
            }
        }

        .images {
            /deep/ .van-uploader__preview {
                margin: 0 15px 10px 0;

                &:nth-child(3n) {
                    margin-right: 0;
                }
            }

            /deep/ .van-uploader__preview-image {
                width: 102px;
                height: 70px;
                background: rgba(60, 107, 240, 0.2);
            }

            /deep/ .van-uploader__preview-delete {
                top: -8px;
                right: -8px;
                width: 20px;
                height: 20px;
                background: url('~@img/icon_delete.png') no-repeat;
                background-size: cover;
            }

            /deep/ .van-uploader__preview-delete-icon {
                display: none;
            }

            /deep/ .van-uploader__mask {
                font-size: 12px;
                color: #fe4c46;
                background: rgba(60, 107, 240, 0.2);

                &::before {
                    content: '';
                    width: 14px;
                    height: 14px;
                    background: url('~@img/icon_warning.png') no-repeat;
                    background-size: cover;
                }
            }

            /deep/ .van-icon {
                display: none;
            }
        }

        /deep/ .van-uploader__input-wrapper {
            .uploader {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 102px;
                height: 70px;
                background: rgba(60, 107, 240, 0.05);
                border-radius: 4px;
                border: 1px solid rgba(60, 107, 240, 0.3);

                .icon_uploader {
                    width: 20px;
                    height: 20px;
                    background: url('~@img/icon_uploader.png') no-repeat;
                    background-size: cover;
                }
            }
        }
    }
}
.content {
    .body {
        display: flex;
        justify-content: center;

        .textarea {
            padding: 9px 12px;
            width: 100%;
            height: 133px;
            border: none;
            background: #f5f7fa;
            resize: none;
            font-size: 14px;
            font-weight: 400;

            &::placeholder {
                font-size: 14px;
                // font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #90949e;
            }
        }
    }
}

/deep/ .van-dialog {
    border-radius: 4px;
}

/deep/ .van-button__text {
    font-size: 18px;
}

.tip {
    padding: 24px 22px;
    font-size: 18px;
    // font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000;
    line-height: 25px;
    background: #fff;
    text-align: center;
}
</style>
